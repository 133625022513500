<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <delete-dialog />
    <v-row no-gutters>
      <v-col>
        <div class="headline">
          <v-icon left large color="primary">mdi-transit-connection-variant</v-icon>
          IAO Cadence Feedback
        </div>
      </v-col>
      <v-col cols="auto">
        <v-btn small class="mr-3" color="secondary" dark @click="summarizeFeedback">
          <v-icon small v-if="!isLoading" left>mdi-creation</v-icon>
          <v-progress-circular v-else indeterminate size="24"></v-progress-circular>
          Summarize Feedback
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <table-filter-dialog :projects="defaultUserProjects" />
      </v-col>
    </v-row>
    <v-dialog v-model="showServiceFeedbackSummary" max-width="600px">
      <v-card>
        <v-card-title>Oncall Service Feedback Summary</v-card-title>
        <v-card-text>
          <span v-html="serviceFeedbackSummary"></span>
        </v-card-text>
        <v-card-actions>
            <v-btn icon @click="onLike">
              <v-icon>mdi-thumb-up-outline</v-icon>
            </v-btn>
            <v-btn icon @click="onDislike">
              <v-icon>mdi-thumb-down-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          <v-btn text @click="showServiceFeedbackSummary = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card outlined class="ma-2 elevation-2">
                <!-- Individual Contact -->
                <v-card-title class="mb-2 d-flex justify-space-between">
                  <v-chip large>
                    <v-icon large left color="primary">mdi-star-outline</v-icon>
                    {{ item.rating }}
                  </v-chip>
                  <v-chip>
                    <v-icon left color="warning">mdi-timer-sand</v-icon>
                    {{ item.hours }} hours
                  </v-chip>
                </v-card-title>
                <!-- Service Name, Project, and Created At -->
                <v-card-subtitle class="mb-2">
                  <div class="mb-2">
                    <v-icon left color="primary">mdi-account-circle</v-icon>
                    <individual v-if="item.individual_contact" :individual="item.individual_contact" />
                    <span v-else>Anonymous</span>
                  </div>
                  <div class="mb-2">
                    <v-icon left color="primary">mdi-account-group</v-icon>
                    {{ item.service.name }}
                    <service v-if="item.service" :service="item.service" />
                  </div>
                  <div class="mb-2">
                    <project-card v-if="item.project" :project="item.project" />
                  </div>
                  <span class="float-right">{{ item.created_at | formatRelativeDate }}</span>
                </v-card-subtitle>
                <!-- Feedback Content -->
                <v-card-text>
                  <div class="mb-1"><v-icon left color="success">mdi-message-text-outline</v-icon>Feedback: {{ item.feedback }}</div>
                  <div class="mb-1"><v-icon left color="primary">mdi-star-outline</v-icon>Rating: {{ item.rating }}</div>
                  <div class="mb-1"><v-icon left color="warning">mdi-timer-sand</v-icon>Effort: {{ item.hours }}</div>
                </v-card-text>
                <!-- Actions -->
                <v-card-actions class="justify-end">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="removeShow(item)">
                        <v-list-item-icon>
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Delete Feedback</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.individual_contact="{ item }">
              <individual v-if="item.individual_contact" :individual="item.individual_contact" />
            </template>
            <template v-slot:item.created_at="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
                </template>
                <span>{{ item.created_at | formatDate }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.project.name="{ item }">
              <v-chip small :color="item.project.color" text-color="white">
                {{ item.project.name }}
              </v-chip>
            </template>
            <template v-slot:item.data-table-actions="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="removeShow(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import DeleteDialog from "@/feedback/service/DeleteDialog.vue"
import Individual from "@/individual/Individual.vue"
import RouterUtils from "@/router/utils"
import TableFilterDialog from "@/feedback/service/TableFilterDialog.vue"
import ProjectCard from "@/project/ProjectCard.vue"
import Service from "@/service/Service.vue"

export default {
  name: "ServiceFeedbackTable",

  components: {
    DeleteDialog,
    Individual,
    TableFilterDialog,
    ProjectCard,
    Service,
  },

  data() {
    return {
      headers: [],
      showServiceFeedbackSummary: false,
      serviceFeedbackSummary: "",
      isLoading: false,
    }
  },

  computed: {
    ...mapFields("service_feedback", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.service",
      "table.options.filters.rating",
      "table.options.filters.feedback",
      "table.options.filters.individual_contact",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
  },

  methods: {
    ...mapActions("service_feedback", ["getAll", "removeShow", "getsummarizeFeedback"]),
    async summarizeFeedback() {
      this.isLoading = true
      try {
        console.log("summarizeFeedback", this.defaultUserProjects)
        const projectId = this.defaultUserProjects[0].id;
        const payload = { projectId: projectId };
        const response = await this.getsummarizeFeedback(payload)
        this.serviceFeedbackSummary = response.data
        this.showServiceFeedbackSummary = true
      } catch (error) {
        console.error("Failed to summarize feedback:", error)
      } finally {
        this.isLoading = false
      }
    },
    onLike() {
      this.showServiceFeedbackSummary = false
    },
    onDislike() {
      this.showServiceFeedbackSummary = false
    },
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [
        vm.q,
        vm.itemsPerPage,
        vm.sortBy,
        vm.descending,
        vm.service,
        vm.rating,
        vm.feedback,
        vm.project,
        vm.individual_contact,
      ],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }
    )
  },
}
</script>
