<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-dialog v-model="display" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-badge :value="numFilters" bordered overlap color="tips" :content="numFilters">
        <v-btn small color="primary" v-on="on"><v-icon small left>mdi-filter</v-icon>Filter</v-btn>

      </v-badge>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Feedback Filters</span>
      </v-card-title>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <project-combobox v-model="local_project" label="Projects" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <service-combobox v-model="local_service" label="Services" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="applyFilters()"> Apply Filters </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sum } from "lodash"
import { mapFields } from "vuex-map-fields"


import ProjectCombobox from "@/project/ProjectCombobox.vue"
import ServiceCombobox from "@/service/ServiceCombobox.vue"

export default {
  name: "FeedbackTableFilterDialog",

  components: {
    ProjectCombobox,
    ServiceCombobox,
  },

  props: {
    projects: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  data() {
    return {
      display: false,
      local_service: [],
      local_project: this.projects,
    }
  },

  computed: {
    ...mapFields("service_feedback", ["table.options.filters.service", "table.options.filters.project"]),

    numFilters: function () {
      return sum([this.service.length, this.project.length])
    },
  },

  methods: {
    applyFilters() {
      // we set the filter values
      this.project = this.local_project
      this.service = this.local_service

      // we close the dialog
      this.display = false
    },
  },
}
</script>
